













import { defineComponent, ref } from '@nuxtjs/composition-api';
import ExtraGuaranteeOption from '~/components/organisms/ExtraGuarantee/ExtraGuaranteeOption/ExtraGuaranteeOption.vue';
import { useExtraGuaranteeOptions } from '~/composables';

export default defineComponent({
  name: 'ExtraGuaranteeOptions',
  components: {
    ExtraGuaranteeOption,
  },
  setup(_props, { emit }) {
    const { guaranteeOptions, hasOnlyOneOption } = useExtraGuaranteeOptions();
    const selectedOption = ref(null);

    const handleSelect = (option) => {
      selectedOption.value = selectedOption.value?.name === option.name ? false : option;
      emit('update:selectedOption', selectedOption.value);
    };

    return {
      guaranteeOptions,
      hasOnlyOneOption,
      selectedOption,
      handleSelect,
    };
  },
});
