


















import { defineComponent } from '@nuxtjs/composition-api';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  name: 'CartSidebarButtons',
  components: {
    Button,
  },
  props: {
    primaryButtonText: {
      type: String,
      required: true,
    },
    secondaryButtonText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
