












import { defineComponent } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';

export default defineComponent({
  name: 'ExtraGuaranteeSidebarProduct',
  components: {
    ImgView,
  },
  props: {
    productName: {
      type: String,
      required: true,
    },
    productImage: {
      type: String,
      required: true,
    },
  },
});
