











































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import ModalHeading from '@/components/molecules/ModalHeading.vue';
import Link from '~/components/atoms/Link/Link.vue';
import ExtraGuaranteeSidebarProduct from
  '~/components/organisms/ExtraGuarantee/ExtraGuaranteeSidebarProduct/ExtraGuaranteeSidebarProduct.vue';
import ExtraGuaranteeOptions from
  '~/components/organisms/ExtraGuarantee/ExtraGuaranteeOptions/ExtraGuaranteeOptions.vue';
import {
  useExtraGuarantee,
  useCartExtended,
  useProductsExtraGuarantee,
  useCartSidebars,
} from '~/composables';
import CartSidebarButtons from '~/components/molecules/CartSidebars/CartSidebarButtons/CartSidebarButtons.vue';

export default defineComponent({
  name: 'ExtraGuaranteeInner',
  components: {
    CartSidebarButtons,
    ImgView,
    ModalHeading,
    Link,
    ExtraGuaranteeSidebarProduct,
    ExtraGuaranteeOptions,
  },
  setup(_props) {
    const { skuForExtraGuarantee, productNameForExtraGuarantee, productImageForExtraGuarantee } = useExtraGuarantee();
    const { productsExtraGuarantee } = useProductsExtraGuarantee();
    const { handleExtraGuaranteeLogic } = useCartSidebars();
    const selectedOption = ref(null);
    const addingExtraGuarantee = ref(false);

    const {
      addExtraGuaranteeProduct,
    } = useCartExtended();
    const parentSku = skuForExtraGuarantee.value;

    const handleAcceptExtraGuarantee = async () => {
      addingExtraGuarantee.value = true;
      const guaranteeSku = selectedOption?.value ? selectedOption.value.sku : undefined;
      await addExtraGuaranteeProduct(parentSku, guaranteeSku);
      addingExtraGuarantee.value = false;
      handleExtraGuaranteeLogic(parentSku);
    };

    const isCartSidebarButtonDisabled = computed(
      () => (!selectedOption.value && productsExtraGuarantee.value.length !== 1) || addingExtraGuarantee.value,
    );

    return {
      handleAcceptExtraGuarantee,
      productNameForExtraGuarantee,
      productImageForExtraGuarantee,
      parentSku,
      handleExtraGuaranteeLogic,
      selectedOption,
      productsExtraGuarantee,
      isCartSidebarButtonDisabled,
    };
  },
});
