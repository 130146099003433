




























import type { PropType } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { ProductVariant } from '~/utils/ct/types/product/ProductVariant';

type ProductVariantWithName = ProductVariant & { _name?: string };

export default defineComponent({
  name: 'ExtraGuaranteeOptions',
  components: {
    ImgView,
  },
  props: {
    hasOneOption: {
      type: Boolean,
      required: true,
    },
    option: {
      type: Object as PropType<ProductVariantWithName>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['select'],
  setup(props) {
    const hasOnlyOneOption = computed(() => props.hasOneOption);

    return {
      hasOnlyOneOption,
    };
  },
});
